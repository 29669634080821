import React, { ReactElement, useLayoutEffect, useRef, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import styled from 'styled-components';
import { DEVICE } from '../../../styles';
import { COLORS } from '../../../styles/theme';

const messages = defineMessages({
  showAll: {
    id: 'textEllipsis.showAll',
    defaultMessage: 'すべて表示',
  },
  close: {
    id: 'textEllipsis.close',
    defaultMessage: '閉じる',
  },
});

const SimpleIndicator = styled.div<{ isHidden: boolean }>`
  margin-left: auto;
  font-size: 14px;
  font-weight: 600;
  color: ${COLORS.white};
  cursor: pointer;
  visibility: ${({ isHidden }) => (isHidden ? 'hidden' : 'visible')};

  @media ${DEVICE.mobileWide} {
    font-size: 12px;
  }
`;

const Bottom = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  @media ${DEVICE.mobileWide} {
    margin-top: 4px;
  }
`;

const Text = styled.div<{ maxLine: number; showPointer: boolean }>`
  --line-height: 1.4;

  overflow: hidden;
  white-space: pre-wrap;
  word-break: break-all;
  line-height: var(--line-height);
  max-height: calc(var(--line-height) * 1em * ${({ maxLine }) => maxLine});

  transition: max-height 0.2s ease-in;
  cursor: ${({ showPointer }) => (showPointer ? 'pointer' : 'auto')};

  border-bottom: 2px solid transparent; // Safariで次の行が少しだけ見えるのを防ぐ
  box-sizing: border-box;
`;

interface Props {
  text: string;
  maxLine: number;
  additionalLine?: ReactElement; // Placed on left in the same line with SimpleIndicator
}
const TextEllipsis: React.FC<Props> = ({ maxLine, text, additionalLine }) => {
  const intl = useIntl();
  const containerRef = React.useRef<HTMLDivElement>(null);
  const textRef = React.useRef<HTMLDivElement>(null);
  const [showIndicator, setShowIndicator] = useState(false);
  const [shallTruncate, setShallTruncate] = useState(true);
  const [fullHeight, setFullHeight] = useState<string | undefined>(undefined);
  const lastCalculatedSizeRef = useRef({ blockSize: 0, inlineSize: 0 });

  useLayoutEffect(() => {
    if (!textRef.current || !containerRef.current) return;
    const containerElement = containerRef.current;
    const textElement = textRef.current;
    const resizeObserver = new ResizeObserver((entries) => {
      if (!entries.length) return;
      const { borderBoxSize, contentRect } = entries[0];
      let blockSize = 0;
      let inlineSize = 0;
      let roundedBlockSize = 0;
      let roundedInlineSize = 0;
      if (borderBoxSize && borderBoxSize.length) {
        blockSize = borderBoxSize[0].blockSize;
        inlineSize = borderBoxSize[0].inlineSize;
      } else {
        // fallback for browsers that don't support borderBoxSize (e.g. below Safari 15.4)
        blockSize = contentRect.height;
        inlineSize = contentRect.width;
      }
      // used rounded values to avoid unnecessary re-calculations
      roundedBlockSize = Math.round(blockSize);
      roundedInlineSize = Math.round(inlineSize);
      if (
        roundedInlineSize !== lastCalculatedSizeRef.current.inlineSize ||
        roundedBlockSize !== lastCalculatedSizeRef.current.blockSize
      ) {
        lastCalculatedSizeRef.current = {
          blockSize: roundedBlockSize,
          inlineSize: roundedInlineSize,
        };
        const textRect = textElement.getBoundingClientRect();
        setFullHeight(`${textRect.height}px`);
        // if already expanded, we don't recalculate to avoid the button suddenly disappearing
        if (shallTruncate) {
          setShowIndicator(textRect.height > blockSize);
        }
      }
    });
    resizeObserver.observe(containerElement);
    return () => {
      resizeObserver.disconnect();
    };
  }, [shallTruncate]);

  return (
    <>
      <Text
        ref={containerRef}
        maxLine={maxLine}
        style={{
          maxHeight: shallTruncate ? undefined : fullHeight,
        }}
        showPointer={showIndicator}
        onClick={() => {
          if (showIndicator) {
            setShallTruncate(!shallTruncate);
          }
        }}
      >
        <div ref={textRef}>{text}</div>
      </Text>
      <Bottom>
        {additionalLine}
        <SimpleIndicator
          isHidden={!showIndicator}
          onClick={() => {
            setShallTruncate(!shallTruncate);
          }}
        >
          {intl.formatMessage(
            shallTruncate ? messages.showAll : messages.close
          )}
        </SimpleIndicator>
      </Bottom>
    </>
  );
};

export default TextEllipsis;
